import React, { Component } from "react";
import { Form, Message, Dropdown, Header, Divider } from "semantic-ui-react";
import gql from "graphql-tag";
import moment from "moment";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import axios from "axios";
import InlineError from "../../shared/InlineError";
import arrivalDepotStack from "./fetchArrivalContainers";
// import getAllDamages from "./fetchAllDamages";

var truckerOptions = [];
var positionOptions = [];
var damageOptions = [];
class AddArrivalPrestack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatch_driver_name: "",
      dispatch_driver_id: "",
      seal_number: "",
      gate_in_stack: "",
      gate_in_time: "",
      mode_of_transport: "",
      type_of_transport: "",
      carrier_id: "",
      mode_of_transport_id: "",
      container_position: "",
      damages: [],
      damage_charges: 0,
      containerDamages: [],
      prevdata: this.props.location.state,
      errors: {},
      loading: false,
      bl_no: Math.floor(10000000 + Math.random() * 90000000),
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.carrier_id) errors.carrier_id = "Can't be blank";
    if (!this.state.mode_of_transport_id)
      errors.mode_of_transport_id = "Can't be blank";
    // if (!this.state.bay_position) errors.bay_position = "Can't be blank"

    return errors;
  };
  onSubmit = () => {
    console.log("Submitting");
    const errors = this.validate();
    console.log("ERRORS::", errors);
    if (Object.keys(errors).length === 0) {
      this._recieveDepotContainer();
      this._addDamages();
      this.createAndDownloadPdf();
    }
    this.setState({ errors });
  };
  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };
  setTransportMode = (e, data) => {
    this.setState({ mode_of_transport: data.value });
  };
  ontype_of_transportChange = (e, data) => {
    this.setState({ type_of_transport: data.value });
  };
  setTruckerValue = (_e, data) => {
    truckerOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ carrier_id: element.id });
        this.setState({ truckerValue: element.value });
      }
    });
  };
  setPositionValue = (_e, data) => {
    positionOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ bay_position: element.id });
        this.setState({ positionValue: element.value });
      }
    });
  };
  gate_in_timeChange = (time) => this.setState({ gate_in_time: time });
  gate_in_stack_Change = (gate_in_stack) => this.setState({ gate_in_stack });
  setDamageValue = (_e, data) => {
    console.log("DATA ::::::::::", data);
    let charges = 0;
    let damages = [];
    data.value.map((element) => {
      const damagedetail = damageOptions.find(
        (damage) => damage.value === element,
      );
      console.log("DATA ::::::::::", damagedetail);
      let damageobject = {
        id: damagedetail.id,
        damage: damagedetail.value,
        manhours: damagedetail.manhours,
        materialCost: damagedetail.materialCost,
        description: damagedetail.description,
      };
      damages.push(damageobject);
      charges = charges + parseFloat(damagedetail.materialCost);
    });
    this.setState({ damage_charges: charges, damages: damages });
  };
  createAndDownloadPdf = () => {
    console.log(this.props.location);
    let data = this.state;
    let containerDamages = [];
    this.state.damages.map((damage) => {
      containerDamages.push({
        preadviseNum: this.props.match.params.id,
        DamageCode: damage.id,
        containerNum: this.props.match.params.containerNum,
        BLNumber: this.state.bl_no,
        repairCode: damage.damage,
        manhours: damage.manhours,
        materialCost: damage.materialCost,
        description: damage.description,
      });
    });
    data.sendemail = true;
    data.containerDamages = containerDamages;
    console.log(data);
    axios
      .post("http://134.209.17.161/pdf/send-email", data)
      .then()
      .then((res) => {});
  };
  render() {
    console.log(this.props.match.params.container_number);
    const { errors } = this.state;
    if (this.props.getAllTruckers.loading === false) {
      let tempOp = this.props.getAllTruckers.getAllTruckers;
      truckerOptions = [];
      tempOp.map((element) => {
        return truckerOptions.push({
          id: element.id,
          key: element.id,
          text: element.truckerName,
          value: element.truckerCode,
        });
      });
    }
    if (this.props.getAvailableYardPosition.loading === false) {
      let tempOp = this.props.getAvailableYardPosition.getAvailableYardPosition;
      positionOptions = [];
      tempOp.map((element) => {
        return positionOptions.push({
          id: element.id,
          key: element.id,
          text: element.positionNumber,
          value: element.positionNumber,
        });
      });
    }
    if (this.props.damageFeed.loading === false) {
      let tempOp = this.props.damageFeed.getAllDamages;
      damageOptions = [];
      tempOp.map((element) => {
        return damageOptions.push({
          id: element.id,
          key: element.id,
          value: element.repairCode,
          description: element.description,
          manhours: element.manhours,
          materialCost: element.materialCost,
          displayValue: element.repairCode,
          text: element.repairCode,
        });
      });
    }
    return (
      <>
        <Header as="h1" color="green" textAlign="center">
          Enter Reception details
        </Header>
        <Divider />
        <Form>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Form.Group widths="equal">
            <Form.Field error={!!errors.dispatch_driver_name}>
              <label>Driver Name </label>
              <input
                value={this.state.dispatch_driver_name}
                type="text"
                onChange={this.handleChange("dispatch_driver_name")}
              />
              {errors.dispatch_driver_name && (
                <InlineError text={errors.dispatch_driver_name} />
              )}
            </Form.Field>
            <Form.Field error={!!errors.dispatch_driver_id}>
              <label>ID No. </label>
              <input
                value={this.state.dispatch_driver_id}
                type="text"
                onChange={this.handleChange("dispatch_driver_id")}
              />
              {errors.dispatch_driver_id && (
                <InlineError text={errors.dispatch_driver_id} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.carrier_id}>
              <label>Carrier ID</label>
              <Dropdown
                value={this.state.truckerValue}
                search
                selection
                options={truckerOptions}
                onChange={this.setTruckerValue.bind(this)}
              />
              {errors.carrier_id && <InlineError text={errors.carrier_id} />}
            </Form.Field>
            <Form.Field error={!!errors.mode_of_transport_id}>
              <label>Reg No.</label>
              <input
                value={this.state.mode_of_transport_id}
                onChange={(e) =>
                  this.setState({ mode_of_transport_id: e.target.value })
                }
              />
              {errors.mode_of_transport_id && (
                <InlineError text={errors.mode_of_transport_id} />
              )}
            </Form.Field>
            <Form.Field error={!!errors.damages}>
              <label>Damages Material Cost : {this.state.damage_charges}</label>
              <Dropdown
                placeholder="Damages"
                fluid
                multiple
                search
                selection
                options={damageOptions}
                onChange={this.setDamageValue.bind(this)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            {/* <Form.Field error={!!errors.position}>
              <label>Position</label>
              <Dropdown
                value={this.state.positionValue}
                search
                selection
                options={positionOptions}
                onChange={this.setPositionValue.bind(this)}
              />
              {errors.bay_position && (
                <InlineError text={errors.bay_position} />
              )}
            </Form.Field> */}
            <Form.Field error={!!errors.seal_number}>
              <label>SEAL No.</label>
              <input
                value={this.state.seal_number}
                onChange={(e) => this.setState({ seal_number: e.target.value })}
              />
              {errors.seal_number && <InlineError text={errors.seal_number} />}
            </Form.Field>
          </Form.Group>
          <Form.Button fluid positive onClick={this.onSubmit}>
            Submit
          </Form.Button>
        </Form>
      </>
    );
  }
  _recieveDepotContainer = async () => {
    const {
      carrier_id,
      mode_of_transport_id,
      seal_number,
      dispatch_driver_id,
      dispatch_driver_name,
      // bay_position,
    } = this.state;
    await this.props
      .recieveDepotContainer({
        variables: {
          id: this.props.match.params.id,
          gate_in_stack: moment().format(),
          gate_in_time: moment().format("HH:mm"),
          mode_of_transport: "ROAD",
          type_of_transport: "TRUCK",
          dispatch_driver_name,
          dispatch_driver_id,
          carrier_id,
          mode_of_transport_id,
          seal_number: seal_number,

          // yard_position_id: bay_position,
        },
        refetchQueries: [{ query: arrivalDepotStack }],
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.history.push("/prestack/operations/arrivals");
  };

  _addDamages = async () => {
    this.state.damages.map(async (damage) => {
      await this.props
        .addDamageMutation({
          variables: {
            preadviseNum: this.props.match.params.id,
            DamageCode: damage.id,
            containerNum: this.props.match.params.container_number,
            BLNumber: this.state.bl_no,
            containerSource: "DTI",
          },
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
}

const recieveDepotContainer = gql`
  mutation recieveDepotContainer(
    $id: ID!
    $container_number: String
    $gate_in_stack: String
    $gate_in_time: String
    $mode_of_transport: String
    $type_of_transport: String
    $carrier_id: String
    $mode_of_transport_id: String
    $dispatch_driver_name: String
    $dispatch_driver_id: String
    $seal_number: String
  ) {
    recieveDepotContainer(
      id: $id
      container_number: $container_number
      gate_in_stack: $gate_in_stack
      gate_in_time: $gate_in_time
      mode_of_transport: $mode_of_transport
      type_of_transport: $type_of_transport
      carrier_id: $carrier_id
      mode_of_transport_id: $mode_of_transport_id
      dispatch_driver_name: $dispatch_driver_name
      dispatch_driver_id: $dispatch_driver_id
      seal_number: $seal_number
    )
  }
`;
const getAllTruckers = gql`
  query getAllTruckers {
    getAllTruckers {
      id
      truckerName
      truckerLongName
      truckerCode
      telNo
    }
  }
`;
const addDamageMutation = gql`
  mutation (
    $preadviseNum: String!
    $DamageCode: ID!
    $BLNumber: ID!
    $containerNum: String!
    $containerSource: String!
  ) {
    addContainerDamages(
      preadviseNum: $preadviseNum
      DamageCode: $DamageCode
      BLNumber: $BLNumber
      containerNum: $containerNum
      containerSource: $containerSource
    )
  }
`;
const getAvailableYardPosition = gql`
  query getAvailableYardPosition {
    getAvailableYardPosition {
      id
      positionNumber
      positionStatus
    }
  }
`;
const getAllDamages = gql`
  query getAllDamages($client: String) {
    getAllDamages(client: $client) {
      id
      repairCode
      description
      manhours
      materialCost
      client
    }
  }
`;
export default compose(
  graphql(getAllTruckers, { name: "getAllTruckers" }),
  graphql(recieveDepotContainer, {
    name: "recieveDepotContainer",
  }),
  graphql(getAvailableYardPosition, {
    name: "getAvailableYardPosition",
  }),
  graphql(getAllDamages, {
    name: "damageFeed",
    options: (props) => ({
      variables: { client: props.match.params.clientCode },
    }),
  }),
  graphql(addDamageMutation, { name: "addDamageMutation" }),
)(AddArrivalPrestack);
