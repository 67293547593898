import React, { Component, Fragment } from 'react'
import {
    Segment, Header, Divider,
    Grid, Form
} from 'semantic-ui-react'

import gql from 'graphql-tag';
import { graphql } from "react-apollo";
import DatePicker from "react-datepicker";

import MonthlyStatView from './MonthlyStatView';

class MonthlyStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: "",
            year: ""
        }
    }


    handleDateFromChange = date => {

        let to_extract = new Date(date)
        let month = to_extract.getMonth() + 1
        let year = to_extract.getFullYear()
        this.setState(date)
        this.setState({ month: month });
        this.setState({ year: year });


    };
    render() {
        // let initial = new Date()
        // this.handleDateFromChange(initial)
        return (
            <Fragment>
                <Form>
                    <Segment.Group horizontal>

                        <Segment>
                            <Form.Field fluid>
                                <label>Choose Month: </label>{" "}
                                <DatePicker
                                    dateFormat="MM.yyyy"
                                    showMonthYearPicker
                                    selected={this.state.date}
                                    onChange={this.handleDateFromChange}
                                />

                            </Form.Field>
                        </Segment>

                    </Segment.Group>
                </Form>
                <MonthlyStatView month={this.state.month} year={this.state.year} />
            </Fragment>
        );
    }
    // _getMonthlyVolume = async (date) => {
    //     console.log(`LEO NI Ile siku. Tumefikiwa`)
    //     // let { selectedDate } = this.state
    //     console.log(`LEO NI Ile siku. Tumechaguliwa ${date}`)
    //     let to_extract = new Date(date)

    //     let month = to_extract.getMonth() + 1
    //     let year = to_extract.getFullYear()
    //     console.log(`mwaka: ${year}. month: ${month}`)
    //     await this.props.getMonthlyVolume({
    //         variables: {
    //             month: month.toString(),
    //             year: year.toString()
    //         }
    //     }).then((result) => {
    //         console.log(result)
    //     })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }
}

export default MonthlyStats