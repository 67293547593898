import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import axios from "axios";
import gql from "graphql-tag";
import { Grid, Header, Segment, Button } from "semantic-ui-react";
import { graphql } from "react-apollo";
import LoadingMessage from "../modules/shared/LoadingMessage";

import { render } from "react-dom";
import { saveAs } from "file-saver";

const GETARCHIVEDPDFDETAIL = gql`
  query getArchivedPdfDetail($id: ID!) {
    getArchivedPdfDetail(id: $id) {
      id
      inTimeICD
      bblEIR
      inDateICD
      pOL
      containerNum
      containerSize
      pOD
      clientCode
      fileBillingNumber
      vessel
      modeOfTransportId
      deliveryDriverName
      partyDeliveringName
      deliveryNoteID
      operatorCode
      recievedICDBy {
        name
      }
      containerDamages {
        id
        repair {
          id
          materialCost
          manhours
          description
          materialCost
          repairCode
        }
      }
    }
  }
`;

class ArchiveDownloads extends Component {
  createAndDownloadPdf = async (data) => {
    // console.log('cont:: ', cont)
    // const { id } = cont
    // const data = await this.props.getArchivedPdfDetail({
    //   variables: {
    //     id: id
    //   }
    // })
    // console.log('data:: ', data)
    data.sendemail = false;
    axios
      .post("http://134.209.17.161/pdf/create-pdf", data)
      .then(
        () =>
          axios(`http://134.209.17.161/pdf/fetch-pdf/${data.bblEIR}`, {
            method: "GET",
            responseType: "blob", //Force to receive data in a Blob Format
          }),
        // axios.get("http://64.227.21.145/:5000/fetch-pdf", {
        //   responseType: "blob",
        // })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "newPdf.pdf");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    if (this.props.getArchivedPdf.loading == false) {
      const { getArchivedPdfDetail } = this.props.getArchivedPdf;

      return (
        <>
          <Header as="h3" color="green" textAlign="center">
            {getArchivedPdfDetail.containerNum}
          </Header>
          <Button
            primary
            onClick={() => this.createAndDownloadPdf(getArchivedPdfDetail)}
          >
            Click to download
          </Button>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}

export default graphql(GETARCHIVEDPDFDETAIL, {
  name: "getArchivedPdf",
  options: (props) => ({ variables: { id: props.match.params.id } }),
})(ArchiveDownloads);
