import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import "rc-time-picker/assets/index.css";

import { Step, Icon, Grid, Segment, Table } from "semantic-ui-react";

import StepContainers from "./StepContainers";
import CashOrderDetails from "./CashOrderDetails";
import Success from "../ShippingLine/Success";
var operatorOptions = [];
var containerOptions = [];
var clientOptions = [];

class RelayOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      numPages: 0,
      pageNumber: 1,
      operatorCode: "",
      clientCode: "",
      containerNum: "",
      containerSize: "",
      containerValue: "",
      partyDelivering: "",
      sOExpiryDate: "",
      step: 1,
      loading: false,
      errors: {},
      message: "",
      items: [],
      user: this.props.user,
    };
  }
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  validate = () => {
    const errors = {};

    if (!this.state.items) errors.items = "Can't be blank";
    if (
      !this.state.fileBillingNo &&
      this.props.user.user.user.serviceType === "RELAY"
    )
      errors.fileBillingNo = "Can't be blank";
    // if (!this.state.file) errors.file = "Upload Storing Order"
    if (Object.keys(errors).length === 0) {
      this._addOrder();
    }
    return errors;
  };
  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  handleChange = (input) => (event) => {
    const errors = {};

    if (input === "containerNum") {
      const containerNum = event.target.value.trim();

      if (containerNum.length === 11) {
        if (
          containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) == null ||
          containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) === false
        ) {
          errors.containerNum = "Wrong entry for container number";
        } else {
          if (Number.isNaN(parseInt(containerNum.substring(4)))) {
            errors.containerNum = "Wrong entry for container number";
          }
        }
      } else {
        errors.containerNum = "Wrong entry for container number";
      }
    }
    this.setState({ errors });
    this.setState({ [input]: event.target.value });
  };
  setOperatorValue = (e, data) => {
    console.log("operatorOptions: ", operatorOptions);
    operatorOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ operatorCode: element.value });
      }

      console.log(this.state);
    });
  };
  setContainerValue = (e, data) => {
    console.log("containerOptions: ", containerOptions);
    containerOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ containerSize: element.id });
        this.setState({ containerCode: element.text });
        this.setState({ containerValue: element.value });
      }

      console.log(this.state);
    });
  };
  setClientValue = (e, data) => {
    console.log("clientOptions: ", clientOptions);
    clientOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ clientCode: element.text });
      }

      console.log(this.state);
    });
  };
  onSubmit = () => {
    const errors = this.validate();

    this.setState({ errors });
  };

  handlesOExpiryDateChange = (date) => {
    this.setState({ sOExpiryDate: date });
  };
  _addOrder = async () => {
    console.log("ADDING ORDER::::", this.props.user);
    const { fileBillingNo, file } = this.state;
    const fileTimeStamp =
      this.props.user.user.user.client.serviceType +
      moment().format("MMDDYYYYhhmmss");
    const containerList = this.state.items;
    await Promise.all(
      containerList.map(async (item) => {
        await this.props.addOrderForm({
          variables: {
            userId: this.props.user.user.user.id,
            file: file,
            fileTimeStamp,
            operatorCode: item.operatorCode,
            clientCode: this.props.user.user.user.client.clientName,
            partyDeliveringName: this.props.user.user.user.client.clientName,
            containerSize: item.size,
            containerNum: item.containerNum,
            fileBillingNumber: fileBillingNo,
            sourceType: this.props.user.user.user.client.serviceType,
          },
        });
      }),
    ).then((result) => {
      console.log(this.props);
      axios
        .post(
          "http://134.209.17.161/pdf/confirmreciept",
          this.props.user.user.user,
        )
        .then((res) => {
          console.log(`statusCode: ${res.statusCode}`);
        });
    });
    this.setState({ loading: false });
    this.setState({ step: 3 });
  };
  updateMessage(event) {
    console.log(event.target.value);
    this.setState({
      message: event.target.value,
    });
  }

  handleClick = () => {
    if (
      this.state.errors.containerNum ||
      this.state.errors.containerSize ||
      this.state.errors.operatorCode
    )
      return;
    if (this.state.containerSize === "") return;
    if (this.state.containerValue === "") return;
    //  if (this.state.clientCode==="")return
    if (!this.state.operatorCode) return;
    if (this.state.items && this.state.items.length) {
      let items = this.state.items;
      // const {items, containerNum, containerSize, containerValue}=this.state
      items.push({
        id: items.length,
        containerNum: this.state.containerNum,
        size: this.state.containerSize,
        containerValue: this.state.containerValue,
        operatorCode: this.state.operatorCode,
      });

      this.setState({
        items: items,
        containerNum: "",
        containerSize: "",
        containerValue: "",
        operatorCode: "",
      });
    } else {
      let items = [];
      // const { containerNum, containerSize, containerValue}=this.state
      items.push({
        id: 1,
        containerNum: this.state.containerNum,
        size: this.state.containerSize,
        containerValue: this.state.containerValue,
        operatorCode: this.state.operatorCode,
        //
      });

      this.setState({
        items: items,
        containerNum: "",
        containerSize: "",
        containerValue: "",
        operatorCode: "",
      });
    }
  };

  handleItemChanged(i, event) {
    var items = this.state.items;
    items[i] = event.target.value;

    this.setState({
      items: items,
    });
  }

  handleItemDeleted = (i) => {
    console.log(i);
    if (this.state.items && this.state.items.length) {
      let items = this.state.items;

      items.splice(i, 1);

      this.setState({
        items: items,
      });
    }
  };

  renderRows(items) {
    if (items && items.length) {
      return items.map(function (o, i) {
        return (
          <Table.Row key={"item-" + i}>
            <Table.Cell>
              <input type="text" value={o.containerNum} />
            </Table.Cell>
            <Table.Cell>
              <input type="text" value={o.containerValue} />
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    console.log(this.props.user.user.user.client);
    const { step } = this.state;

    if (this.props.operatorFeed.loading === false) {
      let tempOp = this.props.operatorFeed.getAllOperators;
      operatorOptions = [];
      tempOp.map((element) => {
        return operatorOptions.push({
          id: element.id,
          key: element.id,
          text: element.operatorName,
          value: element.operatorCode,
        });
      });
    }
    if (this.props.clientFeed.loading === false) {
      console.log(this.props.clientFeed);
      let tempOp = this.props.clientFeed.getAllClients;
      clientOptions = [];

      tempOp.map((element) => {
        return clientOptions.push({
          id: element.id,
          key: element.id,
          text: element.clientName,
          value: element.clientName,
        });
      });
    }
    if (this.props.containerFeed.loading === false) {
      let tempOp = this.props.containerFeed.getContainersForMobile;
      containerOptions = [];
      tempOp.map((element) => {
        return containerOptions.push({
          id: element.id,
          key: element.id,
          text: element.description,
          value: element.containerCode,
        });
      });
    }

    switch (step) {
      case 2:
        return (
          <Grid
            textAlign="center"
            verticalAlign="middle"
            style={{ height: "100%" }}
          >
            <Step.Group widths={3}>
              <Step active>
                <Icon name="box" />
                <Step.Content>
                  <Step.Title>Container</Step.Title>
                </Step.Content>
              </Step>
              <Step disabled>
                <Icon name="list" />
                <Step.Content>
                  <Step.Title>Other Details</Step.Title>
                </Step.Content>
              </Step>
              <Step disabled>
                <Icon name="check" color="green" />
                <Step.Content>
                  <Step.Title>Submitted</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
            <Segment attached>
              <StepContainers
                errors={this.state.errors}
                values={this.state}
                containerOptions={containerOptions}
                clientOptions={clientOptions}
                setClientValue={this.setClientValue}
                operatorOptions={operatorOptions}
                setOperatorValue={this.setOperatorValue}
                renderRows={this.renderRows}
                handleChange={this.handleChange}
                handleItemDeleted={this.handleItemDeleted}
                setContainerValue={this.setContainerValue}
                handleClick={this.handleClick}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                onSubmit={this.onSubmit}
              />
            </Segment>
          </Grid>
        );
      case 1:
        return (
          <Grid
            textAlign="center"
            verticalAlign="middle"
            style={{ height: "100%" }}
          >
            <Step.Group widths={3}>
              <Step disabled>
                <Icon name="box" />
                <Step.Content>
                  <Step.Title>Container</Step.Title>
                </Step.Content>
              </Step>
              <Step active>
                <Icon name="list" />
                <Step.Content>
                  <Step.Title>Other Details</Step.Title>
                </Step.Content>
              </Step>
              <Step disabled>
                <Icon name="check" color="green" />
                <Step.Content>
                  <Step.Title>Submitted</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
            <Segment attached>
              <CashOrderDetails
                errors={this.state.errors}
                values={this.state}
                onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                onFileChange={this.onFileChange}
                clientType={this.props.user.user.user.client.serviceType}
                setClientValue={this.setClientOptionChange}
                operatorOptions={operatorOptions}
                setOperatorValue={this.setOperatorValue}
                handleChange={this.handleChange}
                handlesOExpiryDateChange={this.handlesOExpiryDateChange}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
              />
            </Segment>
          </Grid>
        );

      case 3:
        return (
          <Grid
            textAlign="center"
            verticalAlign="middle"
            style={{ height: "100%" }}
          >
            <Success
              errors={this.state.errors}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );
      default:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            Loading ...
          </Grid>
        );
    }
  }
}

const ADDORDERMUTATION = gql`
  mutation addOrderForm(
    $userId: ID!
    $file: Upload
    $fileTimeStamp: String
    $fileBillingNumber: String
    $containerNum: String
    $containerSize: String
    $operatorCode: String
    $partyDeliveringName: String
    $clientCode: String
    $sourceType: String
  ) {
    addOrderForm(
      userId: $userId
      file: $file
      fileTimeStamp: $fileTimeStamp
      fileBillingNumber: $fileBillingNumber
      containerNum: $containerNum
      containerSize: $containerSize
      partyDeliveringName: $partyDeliveringName
      clientCode: $clientCode
      operatorCode: $operatorCode
      sourceType: $sourceType
      sOExpiryDate: "TBA"
    ) {
      ok
      message
    }
  }
`;
const OPERATORFEED = gql`
  query getAllClients {
    getAllOperators {
      id
      operatorCode
      operatorName
    }
  }
`;
const CONTAINERFEED = gql`
  query getContainersForMobile {
    getContainersForMobile {
      id
      containerSize
      containerCode
      description
    }
  }
`;
const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
      serviceType
      clientType
    }
  }
`;
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default compose(
  graphql(ADDORDERMUTATION, {
    name: "addOrderForm",
  }),
  graphql(CLIENTFEED, {
    name: "clientFeed",
  }),
  graphql(CONTAINERFEED, {
    name: "containerFeed",
  }),
  graphql(OPERATORFEED, {
    name: "operatorFeed",
  }),
)(connect(mapStateToProps, null)(RelayOrder));
