import React, { Component } from 'react'
import {
    Segment, Header, Divider,
    Grid
} from 'semantic-ui-react'
import { Chart } from "react-google-charts";
import gql from 'graphql-tag';
import { graphql } from "react-apollo";
import { JSONPath } from 'jsonpath-plus';

export const data = [
    ["Designation Point/Clients",
        "20Ft", "40Ft",
        "Grand Total"
    ],
    ["CMA", 10, 2, 24],
    ["Global Frieght", 10, 2, 24],
    ["RSS", 10, 2, 24],
    ["Gen Cargo", 10, 2, 24],
];
export const options = {
    curveType: "function",
    legend: { position: "bottom" },
    pageSize: 10,
};
export const piedata = [
    ["Client", "No of Containers"],
    ["CMA", 11],
    ["Global Cargo", 2],
    ["RSS", 2],
    ["SPEDAG", 2],

];
export const pieoptions = {
    // title: "Prestack Volume",
    is3D: true,
};
const grouped = arr => {
    const result = [];
    arr.forEach(item => {
        if (!this[item.clientCode]) {
            this[item.clientCode] = {
                clientCode: item.clientCode, "20Ft": 0, "40Ft": 0
            };
            result.push(this[item.clientCode]);
        }
        if (item.containerCode.startsWith("2")) {
            this[item.clientCode]["20Ft"] += parseInt(item.monthlyTotal)
        } else {
            this[item.clientCode]["40Ft"] += parseInt(item.monthlyTotal);
        }
    }, {});
    return result;
}
const to_display = arr => {
    const result = [["Designation Point/Clients", "20Ft", "40Ft", "Grand Total"],]
    arr.forEach(item => {
        result.push([item.clientCode, item["20Ft"], item["40Ft"], item["20Ft"] + item["40Ft"]])
    })
    return result;
}

class MonthlyStatView extends Component {
    constructor(props) {
        super(props);
    }
    state = {}
    getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
            month: 'long',
        });
    }
    render() {
        let parsedObject
        if (this.props.getMonthlyVolume.getMonthlyVolume != undefined) {
            console.log(this.props.getMonthlyVolume)
            let clients = JSONPath(
                {
                    path: '$..clientCode',
                    json: this.props.getMonthlyVolume.getMonthlyVolume
                }
            )
            let client_set = new Set(clients);
            console.log(clients)
            console.log(client_set)
            let selected_client = JSONPath(
                {
                    path: '$..[?(@.clientCode=="CMA CGM KENYA LTD" && @.containerCode.substring(0,1) == 2)]',
                    json: this.props.getMonthlyVolume.getMonthlyVolume
                }
            )
            console.log("Selected")
            console.log(selected_client)
        }

        let header = `PERFORMANCE FOR ${this.getMonthName(this.props.month).toUpperCase()} ${this.props.year}`
        return (
            <>

                <Divider color="olive" />
                <Header as='h3'>{header}</Header>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>PRESTACK VOLUME HANDLED</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>DEPOT VOLUME HANDLED</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>TOTAL VOLUME HANDLED</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
            </>
        );
    }
}
const getMonthlyVolume = gql`
query getMonthlyVolume($month: String, $year: String) {
  getMonthlyVolume(month: $month, year: $year) {
    clientCode
    containerCode
    position
    monthlyTotal
  }
}
`
export default graphql(
    getMonthlyVolume, {
    name: "getMonthlyVolume",
    options: (props) => ({
        variables: {
            month: props.month.toString(),
            year: props.year.toString()
        }
    })
}
)(MonthlyStatView);